import React from 'react';
import { HeadFC } from 'gatsby';

import Template from '../components/pages/CertificadoZelocode';
import Seo from '../components/Seo';

const CertificadoZelocode = () => {
  return <Template />;
};

export const Head: HeadFC = () => <Seo title="Certificado Zelocode" />;

export default CertificadoZelocode;
