import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Logo from '../../Logo';
import GlobalStyle from '../../../styles/global';

import * as S from './styles';

const Squares = '../../../../static/selo/squares-decoration.png';
const SecureData = '../../../../static/selo/secure-data.png';
const SeloSmall = '../../../../static/selo/selo-small.png';

function CertificadoZelocode() {
  return (
    <>
      <GlobalStyle />

      <S.Wrapper>
        <S.Modal>
          <S.Company>
            <Logo />
            <S.Decoration>
              <StaticImage
                src={SeloSmall}
                alt="Selo ZeloCode LGDP"
                objectFit="contain"
              />
            </S.Decoration>
          </S.Company>
          <S.Content>
            <S.Header>
              <S.Decoration>
                <StaticImage src={Squares} alt="" />
              </S.Decoration>

              <S.Heading>
                Esta empresa está{' '}
                <strong>
                  em conformidade com a Lei Geral de Proteção de Dados (LGPD)
                </strong>
              </S.Heading>

              <S.Decoration>
                <StaticImage src={Squares} alt="" />
              </S.Decoration>
            </S.Header>

            <S.Main>
              <p>
                E realizou melhorias para garantir segurança e proteção dos
                dados pessoais de seus clientes, colaboradores e demais partes
                interessadas.
              </p>

              <S.Details>
                <S.Decoration>
                  <StaticImage src={SecureData} alt="" />
                </S.Decoration>

                <S.List>
                  <li>
                    <span>
                      <strong>Exactaworks:</strong> EXACTAWORKS CONSULTORIA LTDA
                    </span>
                  </li>
                  <li>CNPJ: 17.964.027/0001-05</li>
                  <li>
                    <span>
                      Certificado por:{' '}
                      <S.Link
                        href="https://www.zelocode.com.br"
                        target="_blank"
                        rel="noreferrer"
                      >
                        www.zelocode.com.br
                      </S.Link>
                    </span>
                  </li>
                </S.List>
              </S.Details>

              <S.Logo
                href="https://www.zelocode.com.br"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  width="332"
                  height="38"
                  viewBox="0 0 332 38"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M44.7858 12.7514C45.604 14.9495 45.9502 17.2431 45.8243 19.473C45.5096 25.8124 40.5062 28.8387 34.4644 32.1199C30.9085 34.0631 27.9505 37.7584 23.3247 37.7584C18.6989 37.7584 15.7724 34.0312 12.1851 32.1199C6.14321 28.8387 1.10834 25.8124 0.825131 19.473C0.730727 17.2113 1.07687 14.9176 1.86357 12.7514C-1.15735 6.60321 0.0384297 0.932845 1.64329 0.486862C2.11531 0.359438 5.04183 3.13091 7.15018 5.2334C10.9263 2.0478 16.3388 0.00902195 23.3247 0.26387C30.3106 0.00902195 35.7231 2.0478 39.4992 5.2334C41.6076 3.13091 44.5341 0.359438 45.0061 0.486862C46.611 0.932845 47.8068 6.60321 44.7858 12.7514ZM34.4329 8.22787C28.8945 8.22787 24.3946 12.7833 24.3946 18.3899C24.3946 23.9966 28.8945 28.552 34.4329 28.552C39.9712 28.552 44.4712 23.9966 44.4712 18.3899C44.4712 12.7833 39.9712 8.22787 34.4329 8.22787ZM34.0238 15.9052C32.5448 15.9052 31.349 17.1157 31.349 18.6129C31.349 20.1101 32.5448 21.3207 34.0238 21.3207C35.5028 21.3207 36.6986 20.1101 36.6986 18.6129C36.6986 17.1157 35.5028 15.9052 34.0238 15.9052ZM2.24119 18.3899C2.24119 23.9966 6.7411 28.552 12.2795 28.552C17.8178 28.552 22.3177 23.9966 22.3177 18.3899C22.3177 12.7833 17.8178 8.22787 12.2795 8.22787C6.7411 8.22787 2.24119 12.7833 2.24119 18.3899ZM10.0138 18.6129C10.0138 20.1101 11.2095 21.3207 12.6885 21.3207C14.1675 21.3207 15.3633 20.1101 15.3633 18.6129C15.3633 17.1157 14.1675 15.9052 12.6885 15.9052C11.2095 15.9052 10.0138 17.1157 10.0138 18.6129ZM23.3562 29.4758C22.7268 30.7819 19.7374 32.757 20.587 34.732C21.059 35.847 22.2863 36.1974 23.3562 36.1974C24.4576 36.1974 25.6533 35.8789 26.1254 34.732C26.9435 32.757 23.9855 30.7819 23.3562 29.4758Z"
                    fill="#2D3934"
                  />
                  <g clipPath="url(#clip0_6903_29433)">
                    <path
                      d="M226.892 18.9703C226.892 8.43597 235.351 -0.0594482 245.69 -0.0594482C256.096 -0.0594482 264.488 8.50393 264.488 18.9703C264.488 29.4366 256.029 38 245.69 38C235.284 38 226.892 29.5046 226.892 18.9703ZM245.69 29.1648C251.262 29.1648 255.76 24.6112 255.76 18.9703C255.76 13.3293 251.262 8.77578 245.69 8.77578C240.118 8.77578 235.619 13.3293 235.619 18.9703C235.619 24.6112 240.118 29.1648 245.69 29.1648Z"
                      fill="#52796F"
                    />
                    <path
                      d="M267.844 0.348145L281.405 0.416102C286.239 0.416102 290.468 2.18315 294.094 5.71724C297.719 9.25134 299.599 13.533 299.599 18.6982C299.599 23.7955 297.786 28.2131 294.228 31.8831C290.67 35.5531 286.306 37.3882 281.271 37.3882H267.844V0.348145ZM287.917 25.6985C289.864 23.9314 290.804 21.6207 290.804 18.9021C290.804 16.1836 289.864 13.8728 287.984 12.1058C286.105 10.2708 283.889 9.38727 281.405 9.38727H276.639V28.417H281.472C283.822 28.417 285.97 27.4655 287.917 25.6985Z"
                      fill="#52796F"
                    />
                    <path
                      d="M322.694 23.3879H311.617V28.4851H331.22L331.153 37.3883H307.186C304.836 37.3883 303.493 36.7766 303.09 35.4853C302.889 34.9416 302.822 34.0581 302.822 32.9027V4.90184C302.822 3.20276 303.157 2.04739 303.762 1.36775C304.366 0.756083 305.574 0.41626 307.32 0.41626H331.22L331.153 9.31946H311.617V14.4167H322.761L322.694 23.3879Z"
                      fill="#52796F"
                    />
                    <path
                      d="M213.263 38C202.857 38 194.465 29.4366 194.465 18.9703C194.465 8.43597 202.924 -0.0594482 213.263 -0.0594482H224.341V8.77578H213.263C207.691 8.77578 203.193 13.3293 203.193 18.9703C203.193 24.6112 207.691 29.1648 213.263 29.1648H224.341V38H213.263Z"
                      fill="#52796F"
                    />
                    <path
                      d="M154.519 18.6986C154.519 8.16424 162.978 -0.331177 173.317 -0.331177C183.723 -0.331177 192.115 8.2322 192.115 18.6986C192.115 29.2329 183.656 37.7283 173.317 37.7283C162.978 37.7283 154.519 29.2329 154.519 18.6986ZM173.384 28.8931C178.957 28.8931 183.455 24.3395 183.455 18.6986C183.455 13.0576 178.957 8.50406 173.384 8.50406C167.812 8.50406 163.314 13.0576 163.314 18.6986C163.314 24.3395 167.812 28.8931 173.384 28.8931Z"
                      fill="#52796F"
                    />
                    <path
                      d="M59.7915 0.144409H84.833C85.5715 0.144409 86.31 0.348295 87.0485 0.756075C87.7198 1.16386 88.2569 1.70757 88.6597 2.3872C89.0626 3.06684 89.264 3.81443 89.264 4.76592C89.264 5.64944 89.0626 6.39704 88.6597 6.94075L73.2186 28.5531H89.7339L89.6668 37.2524H64.8267C64.0882 37.2524 63.3497 37.0485 62.6112 36.6407C61.1342 35.8252 60.3957 34.4659 60.3957 32.6309C60.3957 31.7474 60.5971 31.0677 60.9999 30.524L76.4411 8.91168H59.9258L59.7915 0.144409Z"
                      fill="#52796F"
                    />
                    <path
                      d="M138.273 0.212402V29.7085H154.654L154.587 37.1845H133.775C131.425 37.1845 130.082 36.5728 129.68 35.2815C129.478 34.7378 129.411 33.9222 129.411 32.6989V0.212402H138.273Z"
                      fill="#52796F"
                    />
                    <path
                      d="M113.969 23.2519H102.891V28.3491H122.495L122.428 37.2523H98.4605C96.1107 37.2523 94.768 36.6407 94.3652 35.3494C94.1638 34.8057 94.0967 33.9221 94.0967 32.7668V4.76586C94.0967 3.06677 94.4324 1.9114 95.0366 1.23177C95.6408 0.620096 96.8492 0.280273 98.5948 0.280273H122.495L122.428 9.18348H102.891V14.2807H114.036L113.969 23.2519Z"
                      fill="#52796F"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6903_29433">
                      <rect
                        width="271.429"
                        height="38"
                        fill="white"
                        transform="translate(59.7917)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </S.Logo>

              <S.LogoDecoration>
                <svg
                  width="225"
                  height="222"
                  viewBox="0 0 225 222"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M221.58 50.7518C233.582 56.2288 244.091 63.9637 252.472 73.3237C276.472 99.7612 268.665 131.694 257.752 168.77C251.377 190.64 254.353 217.091 235.993 235.498C217.632 253.904 191.171 250.742 169.32 257.423C132.27 268.428 100.233 276.44 73.8603 252.381C64.4775 243.771 56.7164 233.282 51.2115 221.545C14.7338 209.14 -3.10356 181.854 1.49027 173.696C2.85633 171.312 25.5105 170.678 42.2528 170.641C44.5537 142.959 57.9171 113.323 86.6608 86.5372C113.374 57.727 142.978 44.2898 170.654 41.9199C170.649 25.1776 171.226 2.52192 173.607 1.14994C181.754 -3.46421 209.084 14.3052 221.58 50.7518ZM162.47 73.9761C140.487 96.014 140.769 132.018 163.099 154.292C185.429 176.567 221.434 176.759 243.417 154.721C265.4 132.683 265.118 96.6795 242.788 74.405C220.458 52.1305 184.454 51.9383 162.47 73.9761ZM191.424 106.105C185.553 111.99 185.628 121.557 191.591 127.506C197.554 133.454 207.122 133.505 212.992 127.62C218.863 121.735 218.788 112.167 212.825 106.219C206.862 100.271 197.294 100.22 191.424 106.105ZM75.1672 242.444C97.4972 264.718 133.502 264.91 155.485 242.872C177.468 220.835 177.186 184.831 154.856 162.556C132.526 140.282 96.5214 140.09 74.5383 162.128C52.5553 184.165 52.8372 220.169 75.1672 242.444ZM106.907 212.401C112.87 218.35 122.437 218.401 128.308 212.516C134.178 206.631 134.103 197.063 128.14 191.115C122.177 185.167 112.609 185.115 106.739 191.001C100.868 196.886 100.943 206.453 106.907 212.401ZM203.13 202.467C205.834 210.16 201.834 229.902 213.073 234.368C219.387 236.92 225.654 233.428 229.901 229.171C234.272 224.788 237.75 218.765 235.056 212.33C230.437 201.228 210.83 205.152 203.13 202.467Z"
                    fill="#98B9B1"
                    fillOpacity="0.75"
                  />
                </svg>
              </S.LogoDecoration>
            </S.Main>
          </S.Content>
        </S.Modal>
      </S.Wrapper>
    </>
  );
}

export default CertificadoZelocode;
