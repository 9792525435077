import styled from 'styled-components';

export const Decoration = styled.div`
  overflow: hidden;
`;

export const Company = styled.div`
  display: grid;
  position: relative;
  place-content: center;
  background: #4e585e;
  margin-bottom: 6rem;
  min-height: 14rem;
  width: 28rem;

  & > img {
    width: 18rem;
  }

  ${Decoration} {
    right: 0;
    bottom: 0;
    position: absolute;
    transform: translate(50%, 50%);
  }

  ${Decoration}, ${Decoration} img {
    height: 12rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #d4e2de;
  position: relative;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;

  ${Decoration} {
    display: none;
  }
`;

export const Heading = styled.h1`
  font-weight: 500;
  font-size: 2.4rem;
  background: #49645d;
  padding: 3rem 2rem;
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  positon: relative;
  font-size: 1.8rem;
  padding: 2rem;
  color: #212826;
  width: 100%;
  gap: 2rem;
`;

export const Details = styled.div`
  display: grid;
  border: 2px solid #52796f;
  font-size: 1.8rem;
  gap: 0 1rem;

  ${Decoration}, ${Decoration} img {
    min-width: 30rem;
  }

  ${Decoration} {
    transform: translateY(-2rem);
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 1.6rem 3.2rem 1.6rem;
  list-style: none;
  gap: 1rem;

  span {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }

  li {
    display: flex;

    &::before {
      content: '';
      height: 1.6rem;
      max-width: 1.6rem;
      min-width: 1.6rem;
      background: #3b5249;
      margin: 0.2rem 1rem 0 0;
      border-radius: 2px;
      display: block;
    }
  }
`;

export const Link = styled.a`
  color: inherit;
  font-weight: bold;
  text-decoration: none;
`;

export const Logo = styled.a`
  margin-top: 2rem;
  z-index: 5;

  svg {
    max-width: 70%;
  }
`;

export const LogoDecoration = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  max-width: max(30%);
  position: absolute;
  bottom: 0;
  right: 0;

  svg {
    height: fit-content;
  }
`;

export const Modal = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  transition: opacity 0.5s linear;
  align-items: center;
  padding: 2rem;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;

  overflow-y: auto;
  min-height: 100vh;
  width: 100vw;

  @media (min-width: 680px) {
    ${Content} {
      max-width: 74rem;
    }

    ${Header} {
      ${Decoration} {
        display: initial;
      }

      ${Decoration}, ${Decoration} img {
        min-height: 12rem;
        max-height: 18rem;
        min-width: 5rem;
      }
    }

    ${Heading} {
      padding: 1.2rem;
    }

    ${Company} {
      min-height: 18rem;
    }

    ${Main} {
      max-width: min(84%, 68rem);
      padding: 4rem 0 3.2rem;
      gap: 3.2rem;
    }

    ${Details} {
      gap: 0;
      grid-template-columns: 3fr 7fr;

      ${Decoration} {
        transform: scale(1.3) translate(-2rem, -11%);
      }

      ${Decoration}, ${Decoration} img {
        min-width: 26rem;
      }
    }

    ${Main} {
      font-size: 1.8rem;
      padding: 3.2rem 0 5rem;
      gap: 3.2rem;
    }

    ${List} {
      gap: 0;
      padding: 0 1.6rem 0 0;
    }
  }

  @media (min-width: 900px) {
    ${Content} {
      max-width: 84rem;
    }

    ${Heading} {
      font-size: 3rem;
      max-width: 69.6rem;
      padding: 0.8rem;
    }

    ${Details} {
      gap: 1rem;
      grid-template-columns: 4fr 6fr;

      ${Decoration} {
        transform: scale(1.3) translate(-2rem, -11%);
      }

      ${Decoration}, ${Decoration} img {
        min-width: 30rem;
      }
    }

    ${List} {
      li::before {
        margin: 0.4rem 0.8rem 0 0;
      }
    }

    ${LogoDecoration} {
      max-width: max(40%);
    }

    ${Company} {
      min-height: 20rem;
    }
  }

  @media (min-width: 1260px) {
    ${Modal} {
      flex-direction: row;
    }

    ${Heading} {
      font-size: 3.2rem;
    }

    ${Company} {
      margin: 0 0 0 5rem;
      min-height: 34rem;
      width: 34rem;

      & > img {
        width: 30rem;
      }

      ${Decoration} {
        top: 0;
        left: 0;
        transform: translate(-50%, -50%);
        right: unset;
        bottom: unset;
      }

      ${Decoration}, ${Decoration} img {
        height: 15rem;
      }
    }

    ${Logo} {
      margin-top: 5rem;
    }

    ${Main} {
      font-size: 2rem;
      padding: 3.2rem 0 5rem;
    }
  }
`;
